exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-amp-js": () => import("./../../../src/templates/about.amp.js" /* webpackChunkName: "component---src-templates-about-amp-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-home-amp-js": () => import("./../../../src/templates/home.amp.js" /* webpackChunkName: "component---src-templates-home-amp-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-horoscope-amp-js": () => import("./../../../src/templates/horoscope.amp.js" /* webpackChunkName: "component---src-templates-horoscope-amp-js" */),
  "component---src-templates-horoscope-js": () => import("./../../../src/templates/horoscope.js" /* webpackChunkName: "component---src-templates-horoscope-js" */)
}

